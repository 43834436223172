import { all, put, takeLatest, fork, take, call } from 'redux-saga/effects'
import _ from 'lodash'
import { getClient } from 'apolloClient'

import {
  fetchChangeDetectionOptionsError,
  fetchChangeDetectionOptionsSuccess,
  fetchTicketListError,
  fetchTicketListSuccess,
} from './actions'
import { FETCH_CD_OPTIONS, FETCH_TICKET_LIST } from './constants'
import { FETCH_CD_OPTIONS_QUERY, FETCH_TICKET_LIST_QUERY } from './queries'

import { processChangeDetectionOptions } from './utils'

function* fetchTicketList(action) {
  const { payload } = action
  try {
    const client = yield getClient()
    let filter = {}
    if (payload && payload.filters) {
      const keys = _.keys(payload.filters)
      keys.forEach(key => {
        if (Array.isArray(payload.filters[key])) {
          filter[key] = { op: 'IN_LIST', values: payload.filters[key] }
        } else {
          filter[key] = { op: 'EQ', value: payload.filters[key] }
        }
      })
    }
    const result = yield client.query({
      query: FETCH_TICKET_LIST_QUERY,
      variables: { filter },
    })

    if (_.isArray(result.errors) && result.errors.length > 0) {
      throw new Error(JSON.stringify(result.errors))
    }

    const path = ['data', 'Tickets', 'items']
    const data = _.get(result, path, null)
    if (data == null) {
      throw new Error(`no results for path ${path}`)
    }

    yield put(fetchTicketListSuccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error ocurred fetching ticket list', e)
    yield put(fetchTicketListError())
  }
}

function* fetchChangeDetectionOptions() {
  try {
    const client = yield getClient()
    const result = yield client.query({
      query: FETCH_CD_OPTIONS_QUERY,
    })

    const { data, identity } = result.data ?? {}
    if (data == null || identity == null) {
      throw new Error('invalid response')
    }

    const processedResults = processChangeDetectionOptions({ data, identity })
    yield put(fetchChangeDetectionOptionsSuccess({ data: processedResults }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error happen fectching change detection options', e)
    yield put(fetchChangeDetectionOptionsError())
  }
}

const takeLeading = (pattern, saga, ...args) =>
  fork(function* () {
    while (true) {
      const action = yield take(pattern)
      yield call(saga, ...args.concat(action))
    }
  })

export default function* ticketsSaga() {
  yield all([
    takeLatest(FETCH_CD_OPTIONS, fetchChangeDetectionOptions),
    takeLeading(FETCH_TICKET_LIST, fetchTicketList),
  ])
}
