import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import Button from 'components/Button'
import Icon from 'components/Icon'
import styled from 'styled-components'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer from './reducers'
import sagas from './sagas'
import { DOMAIN } from './constants'
import {
  selectObjectives,
  selectObjectivesLoading,
} from 'containers/SonraiData/selectors'
import { fetchObjectives } from 'containers/SonraiData/actions'

import {
  fetchChangeDetectionOptions,
  fetchTicketList,
  setTicketListFilter,
  clearTicketListFilter,
} from './actions'
import { fetchPolicies } from 'containers/ControlFrameworkData/actions'
import { selectTicketListFilterPanel } from 'containers/TicketListFilterPanel/selectors'
import { setTicketListFilterPanel } from 'containers/TicketListFilterPanel/actions'

import { selectLoadedPolicies } from 'containers/ControlFrameworkData/selectors'
import { selectSonraiUserSrn } from 'containers/UserProfileData/selectors'
import { selectTicketList, selectTicketListFilter } from './selectors'

import CreateUpdateTicketModal from 'containers/CreateUpdateTicketModal'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import WithoutPermission from 'containers/PermissionChecker/WithoutPermission'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'
import AssignmentControl from './components/AssignmentControl'
import StatusControl from './components/StatusControl'
import TextLink from 'components/TextLink'
import TicketList from './components/TicketList'

import messages from './messages'

const PageContainer = styled.div`
  padding: 16px;
`

const ControlsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  padding-bottom: 16px;

  .left-container {
    display: flex;
  }
  .right-container {
    text-align: right;
  }
`

export class Tickets extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      createTicketModal: false,
    }

    const locationState = props.location.state || {}
    const initialTableFilters = {}

    if (locationState.severityCategory) {
      initialTableFilters.severityCategory = locationState.severityCategory
    }

    if (locationState.swimlaneSrn) {
      initialTableFilters.swimlaneSrns = [locationState.swimlaneSrn]
    }

    if (locationState.ticketType) {
      initialTableFilters.ticketType = locationState.ticketType
    }

    if (locationState.ticketKey) {
      initialTableFilters.ticketKey = locationState.ticketKey
    }

    if (locationState.resourceSRN) {
      initialTableFilters.resourceSRN = locationState.resourceSRN
    }

    const defaultFilters = !_.isEmpty(initialTableFilters)
      ? {
          status: ['NEW', 'IN_PROGRESS'],
        }
      : { status: ['NEW', 'IN_PROGRESS'], assignedTo: props.currentUserSrn }

    const allFilters = {
      ...initialTableFilters,
      ...defaultFilters,
    }

    this.props.fetchTicketList({ filters: allFilters })

    if (!props.loadedPolicies) {
      props.fetchPolicies()
    }

    if (props.objectives.isEmpty()) {
      props.fetchObjectives()
    }

    props.fetchChangeDetectionOptions()

    if (!_.isEmpty(initialTableFilters)) {
      this.props.setTicketListFilterPanel(initialTableFilters)
    }

    this.props.setTicketListFilter(defaultFilters)
  }

  componentDidUpdate(prevProps) {
    const { ticketListPanelFilters, ticketListFilters } = this.props
    if (
      prevProps.ticketListPanelFilters !== ticketListPanelFilters ||
      prevProps.ticketListFilters !== ticketListFilters
    ) {
      this.fetchTickets()
    }
  }

  fetchTickets = () => {
    const { ticketListPanelFilters, ticketListFilters } = this.props
    const filters = ticketListFilters.merge(ticketListPanelFilters)
    this.props.fetchTicketList({ filters: filters.toJS() })
  }

  componentWillUnmount() {
    this.props.clearTicketListFilter()
  }

  render() {
    return (
      <PageContainer>
        <SectionHeader>
          <DynamicFormattedMessage {...messages.pageTitle} />
        </SectionHeader>
        <ControlsContainer>
          <div className="left-container">
            <div>
              <AssignmentControl
                currentUserSrn={this.props.currentUserSrn}
                ticketListFilters={this.props.ticketListFilters}
                setTicketListFilter={this.props.setTicketListFilter}
                disabled={this.props.ticketList.get('loading')}
              />
            </div>
            <div style={{ margin: '0rem 0rem 0rem 1.5rem' }}>
              <StatusControl
                currentUserSrn={this.props.currentUserSrn}
                ticketListFilters={this.props.ticketListFilters}
                setTicketListFilter={this.props.setTicketListFilter}
                disabled={this.props.ticketList.get('loading')}
              />
            </div>
          </div>
          <div className="right-container">
            <WithPermission permissionName="edit.tickets">
              <Button
                color="primary"
                title="Create Ticket"
                onClick={() => this.setState({ createTicketModal: true })}
              >
                <Icon fa name="plus" style={{ marginRight: '0.3em' }} />
                Create Ticket
              </Button>
            </WithPermission>
            <WithoutPermission permissionName="edit.tickets">
              <WithPermission permissionName="edit.tickettemplates">
                <TextLink color="primary" to="/App/TicketTemplates">
                  <DynamicFormattedMessage
                    {...messages.manageTicketTemplates}
                  />
                </TextLink>
              </WithPermission>
            </WithoutPermission>
          </div>
        </ControlsContainer>
        <TicketList
          ticketList={this.props.ticketList}
          overlayNoRowsTemplate={
            this.props.ticketListFilters.get('assignedTo') ===
            this.props.currentUserSrn
              ? 'No tickets assigned to you matching the filters'
              : 'No tickets matching the filters'
          }
          refreshData={this.fetchTickets}
          objectives={this.props.objectives}
          objectivesLoading={this.props.objectivesLoading}
        />
        <CreateUpdateTicketModal
          isOpen={this.state.createTicketModal}
          toggle={() =>
            this.setState(oldState => ({
              createTicketModal: !oldState.createTicketModal,
            }))
          }
        />
      </PageContainer>
    )
  }
}

Tickets.propTypes = {
  // ~ bound action creators ~
  clearTicketListFilter: PropTypes.func.isRequired,
  fetchChangeDetectionOptions: PropTypes.func.isRequired,
  fetchPolicies: PropTypes.func.isRequired,
  fetchTicketList: PropTypes.func.isRequired,
  setTicketListFilterPanel: PropTypes.func.isRequired,
  setTicketListFilter: PropTypes.func.isRequired,
  fetchObjectives: PropTypes.func.isRequired,

  // ~ redux props ~
  ticketListPanelFilters: ImmutablePropTypes.map.isRequired,
  ticketListFilters: ImmutablePropTypes.map.isRequired,
  currentUserSrn: PropTypes.string.isRequired,
  loadedPolicies: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  ticketList: ImmutablePropTypes.map.isRequired,
  objectives: ImmutablePropTypes.iterable,
  objectivesLoading: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  currentUserSrn: selectSonraiUserSrn,
  loadedPolicies: selectLoadedPolicies,
  ticketList: selectTicketList,
  ticketListPanelFilters: selectTicketListFilterPanel,
  ticketListFilters: selectTicketListFilter,
  objectives: selectObjectives,
  objectivesLoading: selectObjectivesLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchChangeDetectionOptions,
      fetchPolicies,
      fetchTicketList,
      setTicketListFilter,
      setTicketListFilterPanel,
      clearTicketListFilter,
      fetchObjectives,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: DOMAIN, reducer })
const withSaga = injectSaga({
  key: DOMAIN,
  saga: sagas,
  mode: DAEMON,
})

export default compose(withConnect, withReducer, withSaga)(Tickets)
