import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { ButtonGroup } from 'reactstrap'

import Button from 'components/Button'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'

import messages from '../messages'

/**
 * get assigned filter from props
 */
function getAssignedToFilter(props) {
  return props.ticketListFilters.get('assignedTo')
}

/**
 * get and set selected All assignment fitlers
 */
function isAllSelected(props) {
  return getAssignedToFilter(props) === undefined
}

function setAllSelected(props) {
  if (!isAllSelected(props)) {
    const filter = { assignedTo: undefined }
    props.setTicketListFilter(filter)
  }
}

/**
 * get and set myself is selected in filter
 */
function isMyselfSelected(props) {
  return _.isEqual(getAssignedToFilter(props), props.currentUserSrn)
}

function setMyselfSelected(props) {
  if (!isMyselfSelected(props)) {
    const filter = { assignedTo: props.currentUserSrn }
    props.setTicketListFilter(filter)
  }
}

/**
 * get and set unassigned filter
 */
function isUnassigned(props) {
  return _.isEqual(getAssignedToFilter(props), null)
}

function setUnassigned(props) {
  if (!isUnassigned(props)) {
    const filter = { assignedTo: null }
    props.setTicketListFilter(filter)
  }
}

export function AssignmentControl(props) {
  return (
    <ButtonGroup>
      <Button
        onClick={() => setAllSelected(props)}
        color="primary"
        outline={!isAllSelected(props)}
        disabled={props.disabled}
      >
        <DynamicFormattedMessage {...messages.assignmentControlAll} />
      </Button>
      <Button
        onClick={() => setMyselfSelected(props)}
        color="primary"
        outline={!isMyselfSelected(props)}
        disabled={props.disabled}
      >
        <DynamicFormattedMessage {...messages.assignmentControlMyself} />
      </Button>
      <Button
        onClick={() => setUnassigned(props)}
        color="primary"
        outline={!isUnassigned(props)}
        disabled={props.disabled}
      >
        <DynamicFormattedMessage {...messages.assignmentControlUnassigned} />
      </Button>
    </ButtonGroup>
  )
}

AssignmentControl.propTypes = {
  currentUserSrn: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  setTicketListFilter: PropTypes.func.isRequired,
  ticketListFilters: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
}

export default themeable(AssignmentControl)
