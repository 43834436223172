import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { List, fromJS } from 'immutable'

import { ButtonGroup } from 'reactstrap'

import Button from 'components/Button'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'

import messages from '../messages'

/**
 * get assigned filter from props
 */
function getStatusFilter(props) {
  return props.ticketListFilters.get('status') || null
}

function isAllSelected(props) {
  return getStatusFilter(props) == null
}

function setAllSelected(props) {
  if (!isAllSelected(props)) {
    const filter = { status: undefined }
    props.setTicketListFilter(filter)
  }
}

function isOpenSelected(props) {
  return _.isEqual(
    getStatusFilter(props) ?? List(),
    fromJS(['NEW', 'IN_PROGRESS'])
  )
}

function setOpenSelected(props) {
  if (!isOpenSelected(props)) {
    const filter = { status: ['NEW', 'IN_PROGRESS'] }
    props.setTicketListFilter(filter)
  }
}

export function StatusControls(props) {
  return (
    <ButtonGroup>
      <Button
        onClick={() => setOpenSelected(props)}
        color="primary"
        outline={!isOpenSelected(props)}
        disabled={props.disabled}
      >
        <DynamicFormattedMessage {...messages.statusControlOpen} />
      </Button>
      <Button
        onClick={() => setAllSelected(props)}
        color="primary"
        outline={!isAllSelected(props)}
        disabled={props.disabled}
      >
        <DynamicFormattedMessage {...messages.statusControlAll} />
      </Button>
    </ButtonGroup>
  )
}

StatusControls.propTypes = {
  currentUserSrn: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  ticketListFilters: ImmutablePropTypes.map.isRequired,
  setTicketListFilter: PropTypes.func,
  theme: themeShape,
}

export default themeable(StatusControls)
