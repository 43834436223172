import gql from 'graphql-tag'

export const FETCH_TICKET_LIST_QUERY = gql`
  query fetchTicketList($filter: TicketsFilter) {
    Tickets(where: $filter) {
      items(orderBy: [{ severityNumeric: { order: DESC } }], limit: 1000) {
        title
        status
        assignedTo
        severityNumeric
        swimlaneSRNs
        createdDate
        lastModified
        resource {
          srn
          label
          name
          friendlyName
          account
          active
          createdDate
          cloudType
          country
          region
          resourceId
          importance
          swimlaneSRNs
          __typename
        }
        evidence {
          conditions
          path
          count
          fieldType
          boolValue
          intValue
          longValue
          value
          prevBoolValue
          prevIntValue
          prevLongValue
          prevValue
          resourceSet
          regionSet
          userAgentSet
          policyEvidence
        }
        firstSeen
        lastSeen
        createdBy
        ticketKey
        ticketType
        resourceType
        resourceLabel
        actionClassification
        account
        srn
        resourceId
      }
    }
  }
`

export const FETCH_CD_OPTIONS_QUERY = gql`
  query fetchChangeDetectionOptions {
    data: getChangeDetectionOptions(resourceType: Data) {
      keyType
      keyName
    }
    identity: getChangeDetectionOptions(resourceType: Identity) {
      keyType
      keyName
    }
  }
`
